import { Injectable } from '@angular/core';
export const BLOBPATHS = ['/print_shift_reports'];
export const FORMDATAPATHS = ['/purchase_orders/upload'];

// @ts-ignore
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable()
export class HttpInterceptorService implements HttpInterceptorService {
  constructor(private router: Router, private toastController: ToastController) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token') || '';
    const authReq = req.clone({
      headers: new HttpHeaders().set('token', token),
    });
    return next.handle(authReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError(async (error: HttpErrorResponse) => {
        if (error?.status == 401) {
          localStorage.clear()
          this.router.navigate(['']);
          if(error?.url.split('/').pop() ==="login") {
            const toast = await this.toastController.create({
              message: "Sorry, XSite doesn't recognize this user !!",
              duration: 2000,
              cssClass: 'custom-warn-toast',
              position: 'bottom'
            });
            await toast.present();
          } else {
            const toast = await this.toastController.create({
              message: 'Session Expired!!!',
              duration: 2000,
              cssClass: 'custom-warn-toast',
              position: 'bottom'
            });
            await toast.present();
          }
          
        }
        return throwError(error);
      })
    );
  }
}
