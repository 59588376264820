import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  refreshSub: Subject<void> = new Subject<void>();
  isDarkTheme: boolean;
  constructor(private loginService: LoginService, private router: Router) {
    this.loginService.homePage.subscribe(() => {
      this.ionViewWillEnter();
    });
  }
  tab1Root: any = '';
  tab2Root: any = '';
  tab3Root: any = '';
  segmentValue: any = 'sales';
  refreshEvent: any;
  ngOnInit() {
    this.isDarkTheme = JSON.parse(
      localStorage.getItem('isDarkTheme') || 'false'
    );
    this.segmentValue = this.router.url.split('landing/')[1] || 'sales';
  }
  handleRefresh(event) {
    this.refreshEvent = event;
    this.refreshSub.next(this.segmentValue);
  }
  refreshComplete() {
    if (this.refreshEvent) {
      this.refreshEvent.target.complete();
      this.refreshEvent = null;
    }
  }
  ionViewWillEnter() {
    this.segmentValue = 'sales';
  }
  segmentChanged(e) {
    this.segmentValue = e.detail.value;
    const routeUrl = {
      liveSales: '/tabs/landing/liveSales',
      sales: '/tabs/landing/sales',
      lookup: '/tabs/landing/lookup',
    }[this.segmentValue];
    this.router.navigateByUrl(routeUrl);
  }
  logout() {
    this.loginService.logoutFunction().subscribe((res) => {
      if (!!res) {
        localStorage.clear();
        this.router.navigate(['']);
      }
    });
  }

  @ViewChild('popover') popover;

  isOpen = false;

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }
}
