import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-counts-quantity-modal',
  templateUrl: './counts-quantity-modal.component.html',
  styleUrls: ['./counts-quantity-modal.component.css'],
})
export class CountsQuantityModalComponent implements OnInit {
  line: any;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {}
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.line, 'confirm');
  }
}
