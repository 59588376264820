import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/services/login.service';
import { PriceBookService } from 'src/app/services/price-book.service';
import {
  last_month,
  Last_ninety_days,
  Last_seven_days,
  Last_six_month,
  last_thirty_days,
  this_month,
} from 'src/app/utilities/common-usage';
import {
  change_date_to_string,
  date_difference,
} from 'src/app/utilities/dates';
import { Location } from '@angular/common';

@Component({
  selector: 'app-price-book-detail',
  templateUrl: './price-book-detail.component.html',
  styleUrls: ['./price-book-detail.component.css'],
})
export class PriceBookDetailComponent implements OnInit {
  sites: any = [
    {
      id: 'all',
      name: 'All Site',
      external_id: 'All',
    },
  ];
  data = [];
  site = [];
  site_id: any;
  date: any;
  interpolate: string;
  departments: any;
  label = '/mobile/icons/label-outline.svg';
  label_status = false;
  subdepartments: any = [];
  id: any;
  selected_item: any;
  sub_depts: any = [];
  qohs: any;
  tax_labels: any;
  sales_restriction_code: any = [];
  unit_of_order = ['CS', 'EA'];
  margin_history: any;
  permissions: any;
  filter: { site_id: any; starting: any; ending: any; date: any };
  userData: any;
  constructor(
    private cookieService: CookieService,
    private pbService: PriceBookService,
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private toastController: ToastController,
    private activeRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    public location: Location
  ) {}

  ngOnInit(): void {
    this.id = this.activeRoute.snapshot.paramMap.get('id');

    if (this.cookieService.get('site_id')) {
      this.site_id = this.cookieService.get('site_id');
      if (this.site_id != 'all') this.site_id = parseInt(this.site_id);
    } else {
      this.site_id = 'all';
    }

    if (this.cookieService.get('date')) {
      this.date = parseInt(this.cookieService.get('date'));
    } else {
      this.date = 1;
    }

    // if (this.cookieService.get('interpolate') != null) {
    //   this.interpolate = this.cookieService.get('interpolate');
    // } else {
    //   this.interpolate = 'basis';
    // }
    this.filter = {
      site_id: this.site_id,
      starting: null,
      ending: null,
      date: this.date,
    };
    this.changeDatesSupplier(this.date);
    this.getData();
  }

  async getData() {
    this.permissions = await this.pbService.getPermissions();
    this.pbService.getWhoami().subscribe((data) => {
      this.userData = data;
    });
    this.pbService.getDepartments().subscribe((data) => {
      this.departments = data;
    });

    this.pbService.getAllSites().subscribe((data) => {
      if (this.site_id != 'all') {
        let site_exists = this.sites.find((element) => {
          return element.id == this.site_id;
        });
        if (!site_exists) this.site_id = 'all';
        this.cookieService.set('site_id', this.site_id);
      }

      this.sites = this.sites.concat(data);
    });

    if ((this.id != null ? this.id : void 0) != null) {
      this.pbService.getItemById(this.id).subscribe((item) => {
        this.selected_item = { ...item };
        this.pbService.getSubdepartments().subscribe((data) => {
          this.subdepartments = data;
          this.sub_depts = this.subdepartments.filter((sub_dep) => {
            return sub_dep.department_id === this.selected_item.department_id;
          });

          this.cdRef.detectChanges();
        });

        this.pbService.getQohById(this.selected_item.id).subscribe((qohs) => {
          this.qohs = qohs;
        });

        this.pbService
          .getMarginHistory(this.id)
          .subscribe((margin_history: any) => {
            var diff, j, len, mh;
            for (j = 0, len = margin_history.length; j < len; j++) {
              mh = margin_history[j];
              diff = date_difference(mh.created);
              mh.ago = diff;
              diff = null;

              margin_history[j]['percent'] = this.margin(
                mh.unit_price,
                mh.average_cost || mh.last_cost
              );
            }
            this.margin_history = margin_history;
          });

        this.pbService.getPricebookLabels(this.id).subscribe((label: any) => {
          if (label.count > 0) {
            this.label_status = true;
          }
        });

        this.generateGraph();
      });
    }

    this.pbService.getTaxLabels().subscribe((tax_labels) => {
      this.tax_labels = tax_labels;
    });
    this.pbService
      .getSalesRestrictionCodes()
      .subscribe((sales_restriction_code) => {
        this.sales_restriction_code = sales_restriction_code;
      });
  }

  select_sub_dep(selected_dept) {
    this.selected_item.subdepartment_id = null;
    return (this.sub_depts = this.subdepartments.filter(function (sub_dep) {
      return sub_dep.department_id === selected_dept;
    }));
  }

  margin(price, cost) {
    if (cost > 0) {
      return ((price - cost) / price) * 100;
    } else {
      return 0;
    }
  }

  change_site() {
    var ref;
    this.cookieService.set(
      'site_id',
      ((ref = this.site_id) != null ? ref.toString() : void 0) || ''
    );
    this.filter.site_id = this.site_id;
    // return this.get_graph(this.item_id);
  }

  graph_dates = [
    {
      id: 1,
      name: 'This month',
    },
    {
      id: 2,
      name: 'Last month',
    },
    {
      id: 3,
      name: 'Last seven days',
    },
    {
      id: 4,
      name: 'Last thirty days',
    },
    {
      id: 5,
      name: 'Last ninety days',
    },
  ];

  changeDatesSupplier(value) {
    let obj;
    if (value === 1) {
      obj = this_month();
    } else if (value === 2) {
      obj = last_month();
    } else if (value === 3) {
      obj = Last_seven_days();
    } else if (value === 4) {
      obj = last_thirty_days();
    } else if (value === 5) {
      obj = Last_ninety_days();
      // } else if(value === 'Last six months') {
      //   obj = Last_six_month();
    }
    this.filter.starting = obj.fromDate;
    this.filter.ending = obj.toDate;
    this.cookieService.set('date', value.toString());

    this.generateGraph();
  }
  // generateGraph(){

  // }
  graphLoading = false;
  noGraphData = true;
  reports: any = [];
  labels: any[];
  dataSet: any[];
  chartChara = {
    fill: false,
    borderWidth: 1, // Specify bar border width,
    pointRadius: 0,
    tension: 0.4,
    pointHoverRadius: 4,
  };
  basicGraph = true;
  async generateGraph() {
    // if(this.statisticsFormGroup.invalid) {
    //   return
    // }
    try {
      // await this.spinnerService.showSpinner()

      this.graphLoading = true; ////
      this.noGraphData = false; ////

      let body: any = {
        starting: change_date_to_string(this.filter.starting),
        ending: change_date_to_string(this.filter.ending),
        item_id: this.selected_item.id,
      };
      this.filter.site_id === 'all'
        ? null
        : (body.site_id = this.filter.site_id);

      if (body.item_id != null) {
        this.reports = await this.pbService.getSummarizeReports(body); //////
      }

      this.labels = []; /////
      // data = []
      let data_gross_profit = [];
      let data_average_cogs = [];
      let data_sales_amount = [];
      let data_sales_count = [];
      let data_cogs = [];
      this.reports.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      this.reports.map((element) => {
        data_cogs.push(element.cogs);
        data_sales_count.push(element.sales_count);
        data_sales_amount.push(element.sales_amount);
        data_average_cogs.push((element.cogs / element.sales_count).toFixed(2));
        data_gross_profit.push(
          (element.sales_amount - element.cogs).toFixed(2)
        );

        let date = new Date(element.date).toLocaleString('en-US', {
          day: 'numeric',
          month: 'short',
        });

        this.labels.push(date);
      });
      this.dataSet = [];
      this.dataSet.push(
        {
          label: 'Gross Profit',
          data: data_gross_profit,
          borderColor: '#191919',
          backgroundColor: '#191919',
        },
        {
          label: 'Cogs',
          data: data_cogs,
          borderColor: '#2ca02c',
          backgroundColor: '#2ca02c',
        },
        {
          label: 'Sales Count',
          data: data_sales_count,
          borderColor: '#ff7f0e',
          backgroundColor: '#ff7f0e',
        },
        {
          label: 'Sales Amount',
          data: data_sales_amount,
          borderColor: '#7777ff',
          backgroundColor: '#7777ff',
        },
        {
          label: 'Cogs/Item',
          data: data_average_cogs,
          borderColor: '#ff0000',
          backgroundColor: '#ff0000',
        }
      );

      this.graphLoading = false;
      // await this.spinnerService.hideSpinner()
    } catch (e) {
      this.graphLoading = false;
      this.noGraphData = true;
      // await this.spinnerService.hideSpinner()
    }
  }

  change_graph() {
    this.graphLoading = true;

    if (this.basicGraph) {
      this.chartChara = {
        fill: false,
        borderWidth: 1, // Specify bar border width,
        pointRadius: 0,
        tension: 0.4,
        pointHoverRadius: 4,
      };
    } else {
      this.chartChara = {
        fill: false,
        borderWidth: 1, // Specify bar border width,
        pointRadius: 0,
        tension: 0,
        pointHoverRadius: 4,
      };
    }
    this.graphLoading = false;
  }

  label_click() {
    if (this.label_status) {
      this.pbService.deletePricebookLabels(this.id).subscribe(async (d) => {
        this.label_status = false;

        // this.cdr.detectChanges()

        const toast = await this.toastController.create({
          message: 'Removed the item from Shelf Tag!!',
          duration: 2000,
          cssClass: 'custom-toast',
        });
        await toast.present();
      });
    } else {
      this.pbService
        .postPricebookLabels(this.id, { item_id: this.id })
        .subscribe(async (d) => {
          this.label_status = true;
          const toast = await this.toastController.create({
            message: 'Added the item to Shelf Tag!!',
            duration: 2000,
            cssClass: 'custom-toast',
          });
          await toast.present();
        });
    }
  }

  post_item() {
    this.pbService
      .postItemById(this.selected_item.id, this.selected_item)
      .subscribe(
        async (status) => {
          const toast = await this.toastController.create({
            message: 'Success !!  Item Saved!',
            duration: 2000,
            cssClass: 'custom-toast',
            position: 'bottom',
          });

          await toast.present();
        },
        async (err) => {
          const toast = await this.toastController.create({
            message: 'Error !! Item can not be Saved',
            duration: 2000,
            cssClass: 'custom-warn-toast',
            position: 'bottom',
          });

          await toast.present();
        }
      );
  }
}
