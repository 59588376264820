import { Component, OnInit } from '@angular/core';
import { PurchaseOrderService } from 'src/app/services/purchase-order.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as commonFunc from '../../utilities/common-usage';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { ToastController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css'],
})
export class PurchaseOrderComponent implements OnInit {
  commonFunc: any = commonFunc;
  poForm: FormGroup;
  userData: any;
  loading: boolean = false;
  purchaseOrdersList: any;
  suppliersList: any;
  poSource: any;
  poColumns: any = ['po_number', 'supplier_name', 'created', 'total', 'action'];
  columnSort: any = {
    po_number: null,
    supplier_name: null,
    created: null,
    total: null,
  };
  constructor(
    private poService: PurchaseOrderService,
    private analyticsService: AnalyticsService,
    public formBuilder: FormBuilder,
    private toastController: ToastController,
    private router: Router
  ) {
    this.poForm = this.formBuilder.group({
      supplierId: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getAllData();
  }

  handleRefresh(event) {
    this.getAllData(event);
  }

  async getAllData(event = null) {
    try {
      this.userData = await this.analyticsService.whoami();
      const body = {
        params: { status: 'open', site_id: this.userData.site_id },
      };
      this.loading = true;
      const [suppliersList, purchaseOrdersList] = await Promise.all([
        this.poService.getSuppliers(),
        this.poService.getPurchaseOrders(body),
      ]);
      this.suppliersList = suppliersList;
      this.purchaseOrdersList = purchaseOrdersList;
      this.poSource = new MatTableDataSource(this.purchaseOrdersList);
      this.loading = false;
      if (event) event.target.complete();
    } catch (err) {
      if (event) event.target.complete();
      this.loading = false;
      const toast = await this.toastController.create({
        message: 'Something went wrong!!!',
        duration: 1500,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  async addPurchaseOrder() {
    try {
      this.userData = await this.analyticsService.whoami();
      const body = {
        site_id: this.userData.site_id,
        supplier_id: this.poForm.value.supplierId,
        status: 'open',
        payment_type: 'charge',
        discount_amount: 0,
        subtotal: 0,
        taxes: 0,
        total: 0,
      };
      this.loading = true;
      const response: any = await this.poService.addPurchaseOrder(body);
      this.purchaseOrdersList.push(response);
      this.loading = false;
      this.router.navigateByUrl('/purchase-order/detail/' + response.id);
    } catch (err) {
      this.loading = false;
      const toast = await this.toastController.create({
        message: 'Something went wrong!!!',
        duration: 1500,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  async removePurchaseOrder(element) {
    try {
      this.loading = true;
      await this.poService.removePurchaseOrder(element);
      this.purchaseOrdersList = this.purchaseOrdersList.filter(
        (x) => x.id != element.id
      );
      this.poSource = new MatTableDataSource(this.purchaseOrdersList);
      const toast = await this.toastController.create({
        message: 'Purchase Order removed',
        duration: 1500,
        position: 'bottom',
      });
      await toast.present();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      const toast = await this.toastController.create({
        message: 'Something went wrong!!!',
        duration: 1500,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  sortCol(col) {
    this.columnSort[col] = this.columnSort[col]
      ? this.columnSort[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.columnSort).map((x) => {
      if (col != x) this.columnSort[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.columnSort[col] },
      { data: this.purchaseOrdersList },
      false
    );
    this.purchaseOrdersList = sorted.data;
  }
}
