import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { CountsService } from 'src/app/services/counts.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { LoginService } from 'src/app/services/login.service';
import { PriceBookService } from 'src/app/services/price-book.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-counts',
  templateUrl: './counts.component.html',
  styleUrls: ['./counts.component.css'],
})
export class CountsComponent implements OnInit {
  user: any;
  batches: any = [];
  displayedColumns: any = ['created', 'name', 'counts'];
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any = [];
  loading: boolean = false;

  constructor(
    private pbService: PriceBookService,
    private invService: InventoryService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertController: AlertController,
    private toastController: ToastController,
    private countsService: CountsService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  handleRefresh(event) {
    this.getData(event);
  }

  getData(event = null) {
    this.loading = true;
    this.pbService.getWhoami().subscribe((user: any) => {
      this.user = user;
      this.countsService
        .getBatches({
          site_id: this.user.site_id,
          status: 'pending',
        })
        .subscribe((data) => {
          this.batches = data;
          this.dataSource = [...this.batches];
          if (event) event.target.complete();
          this.loading = false;
          // setTimeout(()=>{
          //   this.dataSource.sort = this.sort;
          // }, 10)
        });
    });
  }

  open_count_detail(batch) {
    this.router.navigateByUrl(`tabs/counts/${batch.id}`);
  }

  async create_batch(batch_name) {
    var batch, save_error, save_ok;
    if (this.user.site_id === null) {
      const toast = await this.toastController.create({
        message: 'User is not Assigned for any Site!',
        duration: 3000,
        cssClass: 'custom-warn-toast',
      });
      await toast.present();
      return;
    }
    batch = {
      site_id: this.user.site_id,
      name: batch_name,
      source_code: 'mobile',
    };
    save_ok = (batch) => {
      return this.router.navigateByUrl(`tabs/counts/${batch.id}`);
    };
    save_error = async (err) => {
      const toast = await this.toastController.create({
        message: 'Batch' + err.toString(),
        duration: 3000,
        cssClass: 'custom-warn-toast',
      });
      await toast.present();
    };
    //   return batch.$save(save_ok, save_error);
    this.countsService.postBatches(batch).subscribe(
      (batch) => {
        save_ok(batch);
      },
      (err) => {
        save_error(err);
      }
    );
  }

  tableSort = {
    created: 'none',
    name: 'none',
    counts: 'none',
  };

  headerRow = [
    { id: 'created', label: 'Date', size: 4 },
    { id: 'name', label: 'Name', size: 6 },
    { id: 'counts', label: 'Counts', size: 2 },
  ];

  sortTable(n) {
    Object.keys(this.tableSort).map((element) => {
      if (element !== n) this.tableSort[element] = 'none';
    });

    if (this.tableSort[n] == 'none') {
      this.tableSort[n] = 'asc';
      if (typeof this.dataSource[1][n] == 'number')
        this.dataSource.sort((a, b) => a[n] - b[n]);
      else this.dataSource.sort((a, b) => a[n].localeCompare(b[n]));
    } else if (this.tableSort[n] == 'asc') {
      this.tableSort[n] = 'desc';
      if (typeof this.dataSource[1][n] == 'number')
        this.dataSource.sort((a, b) => b[n] - a[n]);
      else this.dataSource.sort((a, b) => b[n].localeCompare(a[n]));
    } else if (this.tableSort[n] == 'desc') {
      this.tableSort[n] = 'none';
      this.dataSource = [...this.batches];
    }
  }
  async getCountName() {
    const alert: any = await this.alertController.create({
      header: 'Enter count name',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Ok',
          role: 'confirm',
          handler: (data) => {
            console.log(data);
            if (!data[0].length) {
              this.showErrorToast('Count name is required');
              return false;
            }
            this.create_batch(data[0]);
          },
        },
      ],
      inputs: [
        {
          placeholder: 'Name',
          attributes: {
            minlength: 8,
          },
        },
      ],
    });

    await alert.present();
  }
  async showErrorToast(data: any) {
    const toast = await this.toastController.create({
      message: data,
      duration: 1500,
      position: 'top',
    });

    await toast.present();
  }
}
