import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.css'],
})
export class LandingHeaderComponent implements OnInit {
  isDarkTheme: boolean = false;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}
  @ViewChild('popover') popover;
  @Output() headerChangedEvent = new EventEmitter();
  headerSubject = new Subject();
  isOpen = false;
  profileData: any;
  isModelOpen: boolean = false;
  async ngOnInit() {
    this.isDarkTheme = JSON.parse(
      localStorage.getItem('isDarkTheme') || 'false'
    );
    this.profileData = await this.analyticsService.whoami();
    this.headerSubject.subscribe((data) => {
      this.headerChangedEvent.emit('changed');
    });
  }
  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  goToHome() {
    this.router.navigate(['/tabs/landing']);
    this.headerSubject.next(true);
  }
  logout() {
    this.loginService.logoutFunction().subscribe((res) => {
      if (!!res) {
        localStorage.removeItem('su');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        this.router.navigate(['']);
      }
    });
  }
}
