import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: any;
  isDarkTheme: boolean = false;
  loading: boolean = false;
  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}
  ionViewDidEnter() {
    this.isDarkTheme = JSON.parse(
      localStorage.getItem('isDarkTheme') || 'false'
    );
  }

  ngOnInit(): void {
    const userCred = JSON.parse(localStorage.getItem('userCred'));
    this.loginForm = this.formBuilder.group({
      username: [userCred?.username || '', [Validators.required]],
      password: ['', [Validators.required]],
      isRemember: [!!userCred],
    });
  }
  onLogin() {
    if (!!this.loginForm.valid) {
      let formData: any = this.loginForm.value;
      this.loading = true;
      this.loginService.loginFunction(formData).subscribe(
        (res) => {
          this.loading = false;
          if (!!res && !!res.success) {
            if (formData.isRemember) {
              localStorage.setItem('userCred', JSON.stringify(formData));
            } else localStorage.removeItem('userCred');
            localStorage.setItem('su', res.data.superUser);
            localStorage.setItem('username', res?.data?.name);
            localStorage.setItem('token', res?.data?.token);
            this.router.navigate(['/tabs'], { replaceUrl: true });
          }
          return res;
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  }

  async openSite(url) {
    if (Capacitor.isNativePlatform()) {
      await Browser.open({ url });
    } else {
      window.open(url);
    }
  }
}
