import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpInterceptorService } from './services/httpInterceptor.service';
import { PriceBookComponent } from './components/price-book/price-book.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { DatePipe } from '@angular/common';
import { PipeModule } from './utilities/pipes/pipe.module';
import { SortPipe } from './utilities/pipes/sort.pipe';
import { LandingModule } from './components/landing/landing.module';
import { PriceBookDetailComponent } from './components/price-book/price-book-detail/price-book-detail.component';
import { LineChartComponent } from './utilities/line-chart/line-chart.component';
import { PurchaseOrderComponent } from './components/purchase-order/purchase-order.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order/purchase-order-detail/purchase-order-detail.component';
import { CountsComponent } from './components/counts/counts.component';
import { CountsDetailComponent } from './components/counts/counts-detail/counts-detail.component';
import { AuthGuardService } from './services/auth-guard.service';
import { CountsQuantityModalComponent } from './components/counts/counts-quantity-modal/counts-quantity-modal.component';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { BnNgIdleService } from 'bn-ng-idle';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PriceBookComponent,
    AnalyticsComponent,
    PriceBookDetailComponent,
    LineChartComponent,
    PurchaseOrderComponent,
    PurchaseOrderDetailComponent,
    CountsComponent,
    CountsDetailComponent,
    CountsQuantityModalComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MaterialModule,
    PipeModule,
    LandingModule,
  ],
  providers: [
    DatePipe,
    SortPipe,
    AuthGuardService,
    BnNgIdleService,
    PDFGenerator,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
