import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/services/login.service';
import { PriceBookService } from 'src/app/services/price-book.service';
import { Location } from '@angular/common';
import { CountsService } from 'src/app/services/counts.service';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CountsQuantityModalComponent } from '../counts-quantity-modal/counts-quantity-modal.component';

@Component({
  selector: 'app-counts-detail',
  templateUrl: './counts-detail.component.html',
  styleUrls: ['./counts-detail.component.css'],
})
export class CountsDetailComponent implements OnInit {
  user: any;
  site_upc: any;
  id: string;
  counts: any = [];
  batch: any;
  locations: any = [];
  displayedColumns: any = ['upc', 'item_label', 'count'];
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any = [];
  search_value: any;
  new_count: any;
  search_items: any;
  count_location: any;
  location_code: string;
  countsModal: HTMLIonModalElement;
  count: any;
  item_info: any;
  userData: any;
  selectedItemOne: any;
  poService: any;
  modalData: any;
  rows: any;
  // tableSort: {};
  loading: boolean = false;
  selectedUPC: any;
  // loading: boolean = false;

  constructor(
    private cookieService: CookieService,
    private pbService: PriceBookService,
    private toastController: ToastController,
    private activeRoute: ActivatedRoute,
    public location: Location,
    private countsService: CountsService,
    private modalCtrl: ModalController,
    private alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.id = this.activeRoute.snapshot.paramMap.get('id');
    this.getData();
  }

  getData() {
    this.loading = true;
    this.reset_new_item();
    this.pbService.getWhoami().subscribe((user: any) => {
      this.user = user;
      this.pbService.getSiteUpcBySiteId(user.site_id).subscribe((data) => {
        this.site_upc = data;
      });
    });

    this.countsService.getCounts({ batch_id: this.id }).subscribe((counts) => {
      this.counts = _.sortBy(counts, function (count) {
        var dt;
        dt = new Date(count.created);
        return -dt;
      });
      // this.dataSource = new MatTableDataSource(this.counts)
      // setTimeout(()=>{
      //   this.dataSource.sort = this.sort;
      // }, 10)
      this.rows = JSON.parse(JSON.stringify(this.counts));
      this.loading = false;
    });
    this.countsService.getBatchesById(this.id).subscribe((data) => {
      this.batch = data;
    });
    this.countsService.getCountLocations().subscribe((locations) => {
      this.locations = locations;
      this.count_location = this.cookieService.get('location_code')
        ? this.cookieService.get('location_code').toString()
        : 'floor';
    });
  }

  getItems(ev: any) {
    let item_part;
    let part = ev.target.value;
    if (part != null && part.length > 0) {
      if (this.site_upc)
        item_part = this.pbService.items_part(part, this.site_upc);
      this.pbService.searchUPC(part).subscribe((response) => {
        var ref;
        if (response.length === 1) {
          // this.selectItem(response[0]);
          // this.search_value = response[0].description
          this.new_count.item_id =
            (ref = response[0]) != null ? ref.id : void 0;
          // return
          this.selectedUPC = part;
          this.add_item_to_count();
        }
        this.search_items = this.pbService.limitToFilter(response, 15);
      });
    }
  }

  add_item_to_count() {
    var error, ok;
    this.new_count.item_id = this.new_count.item_id;
    this.new_count.location_code = this.count_location;
    this.new_count.count = 0;

    ok = async (count) => {
      count.upc = this.selectedUPC;
      this.add_quantity(count);

      this.counts.push(count);
      this.counts = _.sortBy(this.counts, function (count) {
        var dt;
        dt = new Date(count.created);
        return -dt;
      });
      // this.dataSource = new MatTableDataSource(this.counts)
      //   setTimeout(()=>{
      //     this.dataSource.sort = this.sort;
      //   }, 10)
      this.rows = JSON.parse(JSON.stringify(this.counts));
      const toast = await this.toastController.create({
        message: 'Item count saved !!',
        duration: 2000,
        cssClass: 'custom-toast',
        position: 'bottom',
      });
      await toast.present();
      this.reset_new_item();
    };
    error = async (err) => {
      const toast = await this.toastController.create({
        message: 'Not saved !!',
        duration: 2000,
        cssClass: 'custom-warn-toast',
        position: 'bottom',
      });
      await toast.present();
    };
    // return this.new_count.$save(ok, error);
    this.countsService.addCounts(this.new_count).subscribe(
      (batch) => {
        ok(batch);
      },
      (err) => {
        error(err);
      }
    );
  }

  reset_new_item() {
    if (this.cookieService.get('location_code') != null) {
      this.location_code = this.cookieService.get('location_code');
    } else {
      this.location_code = 'floor';
    }
    this.new_count = {
      batch_id: this.id,
      location_code: this.location_code,
      selected_item_id: null,
    };
    this.search_items = null;
    this.search_value = null;
  }

  async add_quantity(line?) {
    let index = this.rows.indexOf(line);

    var confirm;

    const alert = await this.alertController.create({
      header: 'Set the Count Quantity',
      cssClass: 'count-alert',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
          cssClass: 'count-button-cancel',
        },
        {
          text: 'Save',
          role: 'confirm',
          handler: async (data) => {
            if (data['0'] == null || data['0'] == '') {
              const toast = await this.toastController.create({
                message: 'Please enter valid Quantity !!',
                duration: 2000,
                cssClass: 'custom-warn-toast',
                position: 'bottom',
              });
              await toast.present();
              return false;
            }
            // this.updateQty(element, data['0']);
            line.count = parseFloat(data[0]);
            this.update_count(line, index);
          },
          cssClass: 'count-button-confirm',
        },
      ],
      inputs: [
        {
          type: 'number',
          placeholder: 'Add Quantity',
          min: 0,
          value: line.count,
        },
      ],
    });

    await alert.present();
  }

  update_count(count, pos) {
    var error, ok;
    count.dirty = false;
    ok = async (count) => {
      const toast = await this.toastController.create({
        message: 'Count Updated !!',
        duration: 2000,
        cssClass: 'custom-toast',
        position: 'bottom',
      });
      await toast.present();
      if (!count['upc']) count['upc'] = '';
      this.counts[pos] = count;
      this.rows = JSON.parse(JSON.stringify(this.counts));
    };
    error = async (err) => {
      const toast = await this.toastController.create({
        message: 'Error in Syncing !!',
        duration: 2000,
        cssClass: 'custom-warn-toast',
        position: 'bottom',
      });
      await toast.present();
    };
    this.countsService.addCountsById(count).subscribe(
      (batch) => {
        ok(batch);
      },
      (err) => {
        error(err);
      }
    );
  }

  open_more_info(count) {
    this.count = count;
    this.loading = true;
    this.pbService.getItemById(count.item_id).subscribe((it) => {
      this.modalData = {};
      if (it != null) {
        this.modalData = it;
      }
      this.loading = false;
    });
  }
  change_location() {
    this.cookieService.set(
      'location_code',
      this.count_location.toString() || 'floor'
    );
  }

  remove_lines(count) {
    this.loading = true;
    var error, ok;
    ok = async (status) => {
      this.counts.splice(this.counts.indexOf(count), 1);
      this.rows = JSON.parse(JSON.stringify(this.counts));
      const toast = await this.toastController.create({
        message: 'Success !!  Count Removed !!',
        duration: 2000,
        cssClass: 'custom-toast',
        position: 'bottom',
      });
      await toast.present();
    };
    error = async (err) => {
      const toast = await this.toastController.create({
        message: 'Error !! Item can not be Removed !!',
        duration: 2000,
        cssClass: 'custom-warn-toast',
        position: 'bottom',
      });
      await toast.present();
    };
    this.countsService.removeCountsById(count.id).subscribe(
      (data) => {
        ok(data);
        this.loading = false;
      },
      (err) => {
        error(err);
        this.loading = false;
      }
    );
  }

  tableSort = {
    upc: 'none',
    item_label: 'none',
    count: 'none',
  };

  headerRow = [
    { id: 'upc', label: 'UPC', size: 3 },
    { id: 'item_label', label: 'Desc.', size: 7 },
    { id: 'count', label: 'Count', size: 2 },
  ];

  sortTable(n) {
    var sortfn;

    Object.keys(this.tableSort).map((element) => {
      if (element !== n) this.tableSort[element] = 'none';
    });

    if (this.tableSort[n] == 'none') {
      this.tableSort[n] = 'asc';
      if (typeof this.rows[1][n] == 'number')
        this.rows.sort((a, b) => a[n] - b[n]);
      else this.rows.sort((a, b) => a[n].localeCompare(b[n]));
    } else if (this.tableSort[n] == 'asc') {
      this.tableSort[n] = 'desc';
      if (typeof this.rows[1][n] == 'number')
        this.rows.sort((a, b) => b[n] - a[n]);
      else this.rows.sort((a, b) => b[n].localeCompare(a[n]));
    } else if (this.tableSort[n] == 'desc') {
      this.tableSort[n] = 'none';
      this.rows = [...this.counts];
    }
  }
}
