import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { LoginService } from 'src/app/services/login.service';
import { PriceBookService } from 'src/app/services/price-book.service';

@Component({
  selector: 'app-price-book',
  templateUrl: './price-book.component.html',
  styleUrls: ['./price-book.component.css'],
})
export class PriceBookComponent implements OnInit {
  selected_item: any;
  search_value;
  departments: any;
  subdepartments: any;
  sub_depts: any;
  userData: any;
  permissions: any;
  constructor(
    private pbService: PriceBookService,
    private router: Router,
    private loginService: LoginService,
    private toastController: ToastController
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  isItemAvailable = false;
  items;

  handleRefresh(event) {
    this.getData(event);
  }

  async getData(event = null) {
    this.permissions = await this.pbService.getPermissions();
    this.pbService.getWhoami().subscribe((data) => {
      this.userData = data;
      this.pbService.getDepartments().subscribe((data) => {
        this.departments = data;
        this.pbService.getSubdepartments().subscribe((data) => {
          this.subdepartments = data;
          if (event) event.target.complete();
        });
      });
    });
  }

  getItems(ev: any) {
    this.items = null;
    let part = ev.target.value;
    if (part != null && part.length > 0) {
      this.pbService.searchItem(part).subscribe(async (response) => {
        if (response.length === 1) {
          this.selectItem(response[0]);
          this.search_value = response[0].description;
          // return
        }
        this.items = this.pbService.limitToFilter(response, 15);
      });
    }
  }

  selectItem(search_item) {
    this.search_value = search_item.description;
    this.pbService.getItemById(search_item.id).subscribe((item) => {
      this.selected_item = item;
    });
    this.sub_depts = this.subdepartments.filter((sub_dep) => {
      return sub_dep.department_id === search_item.department_id;
    });
  }

  select_sub_dep(selected_dept) {
    this.selected_item.subdepartment_id = null;
    return (this.sub_depts = this.subdepartments.filter(function (sub_dep) {
      return sub_dep.department_id === selected_dept;
    }));
  }

  logout() {
    this.loginService.logoutFunction().subscribe((res) => {
      if (!!res) {
        localStorage.clear();
        this.router.navigate(['']);
      }
    });
  }

  @ViewChild('popover') popover;

  isOpen = false;

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  post_item() {
    this.pbService
      .postItemById(this.selected_item.id, this.selected_item)
      .subscribe(
        async (status) => {
          const toast = await this.toastController.create({
            message: 'Success !!  Item Saved!',
            duration: 2000,
            cssClass: 'custom-toast',
            position: 'bottom',
          });

          await toast.present();
        },
        async (err) => {
          const toast = await this.toastController.create({
            message: 'Error !! Item can not be Saved',
            duration: 2000,
            cssClass: 'custom-warn-toast',
            position: 'bottom',
          });

          await toast.present();
        }
      );
  }

  // async presentToast(position: 'top' | 'middle' | 'bottom') {
  //   const toast = await this.toastController.create({
  //     message: 'Hello Styled World!',
  //     duration: 30000,
  //     cssClass: 'custom-toast',
  //   });
  //   await toast.present();
  // }

  search_clear() {
    this.selected_item = null;
    this.search_value = null;
  }
}
// 005980050123
