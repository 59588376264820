import { Component } from '@angular/core';
import {
  ThemeDetection,
  ThemeDetectionResponse,
} from '@awesome-cordova-plugins/theme-detection';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isDarkTheme: boolean = false;
  constructor(public platform: Platform) {
    this.showSplash();
    this.platform.ready().then(() => {
      this.setTheme();
      this.hideSplash();
    });
    this.platform.resume.subscribe(async () => {
      this.setTheme();
    });
  }
  async showSplash() {
    await SplashScreen.show({
      showDuration: 2000,
      autoHide: true,
    });
  }
  async hideSplash() {
    await SplashScreen.hide();
  }
  async setTheme() {
    const darkThemeMq = window?.matchMedia('(prefers-color-scheme: dark)');
    localStorage.setItem('isDarkTheme', String(darkThemeMq?.matches || false));
    if (Capacitor.isNativePlatform()) {
      ThemeDetection.isAvailable().then((res: ThemeDetectionResponse) => {
        if (res.value) {
          ThemeDetection.isDarkModeEnabled().then(
            (res: ThemeDetectionResponse) => {
              this.isDarkTheme = res.value;
              localStorage.setItem('isDarkTheme', String(this.isDarkTheme));
            }
          );
        }
      });
    }
  }
}
