import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { IonInfiniteScroll, ToastController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics.service';
import * as commonFunc from '../../utilities/common-usage';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css'],
})
export class SalesComponent implements OnInit {
  @Input() events: Observable<void>;
  @Output() refreshCompletEvt = new EventEmitter<any>();
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  salesForm: FormGroup;
  commonFunc: any = commonFunc;
  userData: any;
  realTimeItemList: any;
  realTimeItemDetails: any = [];
  segmentValue: any = 'list';
  sub_total: any;
  sum_tax: any;
  loading: boolean = false;
  realTimeItemSource: any;
  realTimeItemDetailSource: any;
  realTimeItemListPartial: any = [];
  realTimeItemColumns: any = [
    'event_sequence',
    'ts',
    'amount_sold',
    'payment_type',
  ];
  realTimeDetailColumns: any = [
    'item_id',
    'item_description',
    'item_quantity',
    'item_amount',
    'item_tax_one',
  ];
  isDarkTheme: boolean;
  columnSort: any = {
    event_sequence: null,
    ts: null,
    amount_sold: null,
    payment_type: null,
  };
  columnSortDetail: any = {
    item_id: null,
    item_description: null,
    item_quantity: null,
    item_amount: null,
    item_tax_one: null,
  };
  maxDate: any;
  constructor(
    public datepipe: DatePipe,
    public formBuilder: FormBuilder,
    private analyticsService: AnalyticsService,
    private toastController: ToastController,
    private pdfGen: PDFGenerator,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.salesForm = this.formBuilder.group({
      business_date: [new Date().toJSON(), [Validators.required]],
    });
    const currentDate = new Date();
    this.maxDate = currentDate.toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.isDarkTheme = JSON.parse(
      localStorage.getItem('isDarkTheme') || 'false'
    );
    this.getAllData();
    this.events?.subscribe(async (data: any) => {
      if (data == 'sales') {
        await this.getAllData();
        this.refreshCompletEvt.emit();
      }
    });
  }

  async getAllData() {
    try {
      const { business_date } = this.salesForm.value;
      this.loading = true;
      document.getElementById('listEle').click();
      this.userData = await this.analyticsService.whoami();
      const body = {
        params: {
          business_date: this.datepipe.transform(business_date, 'yyyy-MM-dd'),
          site_id: this.userData.site_id,
        },
      };
      this.realTimeItemList = await this.analyticsService.getRealTimeItemList(
        body
      );
      this.realTimeItemDetailSource = new MatTableDataSource([]);
      this.realTimeItemListPartial = [];
      this.realTimeItemDetails = [];
      this.loadMoreListData();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.createToast('Something went wrong!!!');
    }
  }

  loadMoreListData(event = null) {
    if (event) event.target.complete();
    if (this.realTimeItemList.length === this.realTimeItemListPartial.length) {
      if (event) event.target.disabled = true;
    } else {
      this.realTimeItemListPartial = [
        ...this.realTimeItemListPartial,
        ...this.realTimeItemList.slice(
          this.realTimeItemListPartial.length,
          this.realTimeItemListPartial.length + 10
        ),
      ];
      this.realTimeItemSource = new MatTableDataSource(
        this.realTimeItemListPartial
      );
      this.infiniteScroll.disabled = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  async printContent(divName) {
    document.getElementById(divName).style.display = 'grid';
    const printContents = document.getElementById(divName).innerHTML;
    document.getElementById(divName).style.display = 'none';
    if (Capacitor.isNativePlatform()) {
      const options = {
        documentSize: 'A4',
        type: 'share',
        fileName: 'Sales-Details.pdf',
      };
      await this.pdfGen.fromData(printContents, options);
    } else {
      const popupWin = window.open('', '_blank', 'width=1200,height=800');
      popupWin.document.open();
      popupWin.document.write(
        '<html><head><link rel="stylesheet" type="text/css" href="styles.css" media="print" /></head><body onload="window.print()">' +
          printContents +
          '</body></html>'
      );
      popupWin.document.close();
    }
  }
  async goToDetailsSegment(element) {
    try {
      const { business_date } = this.salesForm.value;
      const { event_sequence, shift_code } = element;
      document.getElementById('detEle').click();
      this.loading = true;
      this.userData = await this.analyticsService.whoami();
      const body = {
        params: {
          business_date: this.datepipe.transform(business_date, 'yyyy-MM-dd'),
          site_id: this.userData.site_id,
          event_sequence,
          shift_code,
        },
      };
      const realTimeItemDetails =
        await this.analyticsService.getRealTimeItemDetails(body);
      this.realTimeItemDetails = realTimeItemDetails;
      this.sub_total = this.realTimeItemDetails.reduce(
        (res, curr) => res + curr.item_amount,
        0
      );
      this.sum_tax = this.realTimeItemDetails.reduce(
        (res, curr) => res + curr.item_tax_one + curr.item_tax_two,
        0
      );
      this.realTimeItemDetailSource = new MatTableDataSource(
        this.realTimeItemDetails
      );
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.createToast('Something went wrong!!!');
    }
  }

  segmentChanged(e) {
    this.segmentValue = e.detail.value;
  }

  addDate() {
    const { business_date } = this.salesForm.value;
    let date = new Date(business_date);
    date.setDate(date.getDate() + 1);
    this.salesForm.patchValue({ business_date: date.toJSON() });
    this.getAllData();
  }
  subtractDate() {
    const { business_date } = this.salesForm.value;
    let date = new Date(business_date);
    date.setDate(date.getDate() - 1);
    this.salesForm.patchValue({ business_date: date.toJSON() });
    this.getAllData();
  }

  async createToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 1500,
      position: 'bottom',
    });
    await toast.present();
  }
  sortColList(col) {
    this.columnSort[col] = this.columnSort[col]
      ? this.columnSort[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.columnSort).map((x) => {
      if (col != x) this.columnSort[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.columnSort[col] },
      { data: this.realTimeItemListPartial },
      false
    );
    this.realTimeItemListPartial = sorted.data;
  }
  sortColDetail(col) {
    this.columnSortDetail[col] = this.columnSortDetail[col]
      ? this.columnSortDetail[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.columnSortDetail).map((x) => {
      if (col != x) this.columnSortDetail[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.columnSortDetail[col] },
      { data: this.realTimeItemDetails },
      false
    );
    this.realTimeItemDetails = sorted.data;
  }
  print() {
    window.print();
  }
}
