import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private apiEndPoint: string = environment.apiEndPoint + 'api/1/';
  constructor(private http: HttpClient) {}

  whoami() {
    return lastValueFrom(this.http.get(this.apiEndPoint + `whoami`));
  }

  getRealTimeFuelTank(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `real_time/fuel_tank`, data)
    );
  }
  getRealTimeFuel(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `real_time/fuel`, data)
    );
  }
  getContent() {
    return lastValueFrom(this.http.get(this.apiEndPoint + `ui/content`));
  }
  getRealTimeCount(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `real_time/count`, data)
    );
  }
  topDepartmentSales(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `department_sales`, data)
    );
  }
  topItemSales(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `item_sales_summary`, data)
    );
  }
  getRealTimeItems(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `real_time/item`, data)
    );
  }
  getRealTimeItemList(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `real_time/list`, data)
    );
  }
  getRealTimeItemDetails(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `real_time/details`, data)
    );
  }
}
