import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  private apiEndPoint: string = environment.apiEndPoint + 'api/1/';
  constructor(private http: HttpClient) {}

  getSuppliers() {
    return lastValueFrom(this.http.get(this.apiEndPoint + `suppliers`));
  }

  getPurchaseOrders(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `purchase_orders`, data)
    );
  }

  addPurchaseOrder(data) {
    return lastValueFrom(
      this.http.post(this.apiEndPoint + `purchase_orders`, data)
    );
  }

  removePurchaseOrder(data) {
    return lastValueFrom(
      this.http.delete(this.apiEndPoint + `purchase_orders/${data.id}`)
    );
  }

  removePurchaseOrderDetail(data) {
    return lastValueFrom(
      this.http.delete(
        this.apiEndPoint +
          `purchase_orders/${data.purchase_order_id}/lines/${data.id}`
      )
    );
  }

  getPurchaseOrderLinesById(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `purchase_orders/${data.id}/lines`)
    );
  }

  getPurchaseOrderById(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `purchase_orders/${data.id}`)
    );
  }

  updatePurchaseOrderById(data) {
    return lastValueFrom(
      this.http.post(this.apiEndPoint + `purchase_orders/${data.id}`, data)
    );
  }

  updatePurchaseOrderLinesById(data) {
    return lastValueFrom(
      this.http.post(
        this.apiEndPoint +
          `purchase_orders/${data.purchase_order_id}/lines/${data.id}`,
        data
      )
    );
  }

  getTimeLineById(data) {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `qoh/itemline`, data)
    );
  }

  getItemSalesByDay(data) {
    return lastValueFrom(
      this.http.get(
        this.apiEndPoint + `purchase_orders/item_sales_days/${data.item_id}`,
        data
      )
    );
  }

  getPoSettings() {
    return lastValueFrom(this.http.get(this.apiEndPoint + `po/settings`));
  }

  addPurchaseOrderLinesById(data) {
    return lastValueFrom(
      this.http.post(
        this.apiEndPoint + `purchase_orders/${data.id}/lines/`,
        data
      )
    );
  }
}
