import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order/purchase-order-detail/purchase-order-detail.component';
import { AuthGuardService } from './services/auth-guard.service';
const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: '',
    loadChildren: () =>
      import('../app/components/tabs/tabs.module').then((m) => m.TabsModule),
  },
  {
    path: 'purchase-order/detail/:id',
    component: PurchaseOrderDetailComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
