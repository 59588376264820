function spi(t) {
  t = t.replace(/^0+/, "");
  if (t === "") {
    t = "0";
  }
  return parseInt(t);
};

export function  date_and_time(d) {
    let date, dt, month;
    if ((d != null) && d !== '') {
      date = new Date(d);
      month = (date.getMonth()) + 1;
      dt = date.getFullYear() + "-" + month + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      return dt;
    } else {
      return null;
    }
  }

  export function parse(d) {
    var date, day, dt, hour, minutes, month, seconds, time, x, year;
        if (d == null) {
          return null;
        }
        d = d.replace(/[^0-9: -]/g, " ");
        [date, time] = d.split(" ");
        time || (time = "00:00:00");
        [year, month, day] = (function() {
          var i, len, ref, results;
          ref = date.split("-");
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            x = ref[i];
            results.push(spi(x));
          }
          return results;
        })();
        [hour, minutes, seconds] = (function() {
          var i, len, ref, results;
          ref = time.split(":");
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            x = ref[i];
            results.push(spi(x));
          }
          return results;
        })();
        dt = new Date(year, month - 1, day, hour, minutes, seconds);
        return dt;
  }

export function change_date_to_string (date) {
  var temp_date;
  if ((date != null) && date !== '') {
    temp_date = new Date(date);
    temp_date = temp_date.toISOString();
    temp_date = temp_date.slice(0, 10).toString();
    return temp_date;
  } else {
    return null;
  }
}

export function date_difference(date1) {
        
  var current_date, date1_ms, date2_ms, diff_margin, difference;
  if (!date1) {
  return null;
  }
  date1 = new Date(date1);
  date1_ms = date1.getTime();
  current_date = new Date();
  date2_ms = current_date.getTime();
  difference = Math.round((date2_ms - date1_ms) / (1000 * 60 * 60 * 24));
  if (difference < 30) {
  diff_margin = difference + ' days ago';
  } else {
  diff_margin = Math.round(difference / 30);
      if (diff_margin > 1) {
          diff_margin = diff_margin + ' months ago';
      } else {
          diff_margin = diff_margin + ' month ago';
      }
  }
  
  return diff_margin;
}