import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingRoutingModule } from './landing-route.module';
import { LandingComponent } from './landing.component';
import { LiveSalesComponent } from '../live-sales/live-sales.component';
import { MaterialModule } from 'src/app/material.module';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
import { SalesComponent } from '../sales/sales.component';
import { InventoryComponent } from '../inventory/inventory.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    LandingRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LandingComponent,
    LandingHeaderComponent,
    LiveSalesComponent,
    SalesComponent,
    InventoryComponent,
  ],
  exports: [LandingHeaderComponent],
})
export class LandingModule {}
