export function last_thirty_days() {
  const toDate = new Date();
  let fromDate = new Date();
  fromDate.setDate(toDate.getDate() - 30);
  return { fromDate, toDate };
}

export function this_month() {
  const date = new Date();
  date.setDate(1);
  const fromDate = date;
  const toDate = new Date();
  return { fromDate, toDate };
}

export function last_month() {
  let month;
  let date = new Date();
  date.setDate(1);
  month = date.getMonth();
  month = month - 1;
  date.setMonth(month);
  const fromDate = date;
  date = new Date();
  date.setDate(0);
  const toDate = date;
  return { fromDate, toDate };
}

export function Last_seven_days() {
  let d = new Date();
  let today;
  d = new Date();
  today = d.getDate();
  d.setDate(today - 7);
  const fromDate = d;
  const toDate = new Date();
  return { fromDate, toDate };
}

export function Last_ninety_days() {
  let d = new Date();
  let today;
  d = new Date();
  today = d.getDate();
  d.setDate(today - 90);
  const fromDate = d;
  const toDate = new Date();
  return { fromDate, toDate };
}
export function Last_six_month() {
  let d = new Date();
  let today;
  today = d.getDate();
  d.setDate(today - 182);
  const fromDate = d;
  const toDate = new Date();
  return { fromDate, toDate };
}

export function sortData(event, source, isForm) {
  const newRows = isForm
    ? source.data.filter(({ value }) => value?.isNewRow)
    : source.data.filter(({ isNewRow }) => isNewRow);
  const restRows = isForm
    ? source.data.filter(({ value }) => !value?.isNewRow)
    : source.data.filter(({ isNewRow }) => !isNewRow);
  if (!event.active || event.direction === '') {
    source.data = [...newRows, ...restRows];
    return;
  }
  const sortedRows = restRows.sort((a, b) => {
    let isAsc: boolean = event.direction === 'asc';
    if (isForm) {
      if (typeof a.value[event.active] == 'object')
        return compare(
          (a.value[event.active].name || '').toString().toLowerCase(),
          (b.value[event.active].name || '').toString().toLowerCase(),
          isAsc
        );
      else if (
        typeof a.value[event.active] == 'string' &&
        isNaN(a.value[event.active])
      )
        return compare(
          (a.value[event.active] || '').toString().toLowerCase(),
          (b.value[event.active] || '').toString().toLowerCase(),
          isAsc
        );
      else if (!isNaN(a.value[event.active]))
        return compare(
          parseFloat(a.value[event.active]),
          parseFloat(b.value[event.active]),
          isAsc
        );
      else return compare(a.value[event.active], b.value[event.active], isAsc);
    } else {
      if (typeof a[event.active] == 'object' && a[event.active])
        return compare(
          (a[event.active]?.name || '').toString().toLowerCase(),
          (b[event.active]?.name || '').toString().toLowerCase(),
          isAsc
        );
      else if (typeof a[event.active] == 'string' && isNaN(a[event.active]))
        return compare(
          (a[event.active] || '').toString().toLowerCase(),
          (b[event.active] || '').toString().toLowerCase(),
          isAsc
        );
      else if (!isNaN(a[event.active]))
        return compare(
          parseFloat(a[event.active]),
          parseFloat(b[event.active]),
          isAsc
        );
      else return compare(a[event.active], b[event.active], isAsc);
    }
  });
  source.data = [...newRows, ...sortedRows];
  return source;
}

export function compare(a: any = '', b: any = '', isAsc: boolean): number {
  if (a < b) {
    return -1 * (isAsc ? 1 : -1);
  } else if (a > b) {
    return 1 * (isAsc ? 1 : -1);
  } else {
    return 0 * (isAsc ? 1 : -1);
  }
}
