import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { PriceBookService } from 'src/app/services/price-book.service';
import { PurchaseOrderService } from 'src/app/services/purchase-order.service';
import * as commonFunc from '../../../utilities/common-usage';
import * as _ from 'lodash';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
  styleUrls: ['./purchase-order-detail.component.css'],
})
export class PurchaseOrderDetailComponent implements OnInit {
  id: any;
  items: any = null;
  search_value: any;
  loading: boolean = false;
  modalLoading: boolean = false;
  itemSource: any = [];
  poLinesList: any;
  poById: any;
  liveSalesColumns: any = [
    'description',
    'avg_price',
    'amount_sold',
    'qty_sold',
  ];
  itemColumns: any = [
    'plu',
    'description',
    'qty_ordered',
    'subTotal',
    'action',
  ];
  commonFunc: any = commonFunc;
  selectedItem: any;
  selectedItemOne: any;
  userData: any;
  columnSort: any = {
    plu: null,
    description: null,
    qty_ordered: null,
    subTotal: null,
  };
  constructor(
    private pbService: PriceBookService,
    private route: ActivatedRoute,
    private poService: PurchaseOrderService,
    private analyticsService: AnalyticsService,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getAllData();
  }

  async getAllData() {
    try {
      this.loading = true;
      const body = { id: this.id };
      const [poLinesList, poById, userData] = await Promise.all([
        this.poService.getPurchaseOrderLinesById(body),
        this.poService.getPurchaseOrderById(body),
        this.analyticsService.whoami(),
      ]);
      this.poLinesList = poLinesList;
      this.poById = poById;
      this.userData = userData;
      this.initializeTable();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.toastMsg('Something went wrong!!!');
    }
  }

  initializeTable() {
    this.poLinesList = this.poLinesList.reduce(
      (res, curr) => [
        ...res,
        {
          ...curr,
          subTotal: (curr?.qty_ordered || 0) * (curr?.unit_cost || 0),
        },
      ],
      []
    );
    this.itemSource = new MatTableDataSource(this.poLinesList);
  }

  getItems(ev: any) {
    this.items = null;
    let part = ev.target.value;
    if (part != null && part.length > 0) {
      this.modalLoading = true;
      this.pbService.searchItem(part).subscribe((response) => {
        this.items = this.pbService.limitToFilter(response, 15);
        this.modalLoading = false;
      });
    }
  }

  async editQty(element) {
    const alert = await this.alertController.create({
      header: 'Quantity Ordered',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Save',
          role: 'confirm',
          handler: async (data) => {
            if (data['0'] == null || data['0'] == '') {
              this.toastMsg('Please enter valid Quantity');
              return false;
            }
            this.updateQty(element, parseFloat(data['0']));
          },
        },
      ],
      inputs: [
        {
          type: 'number',
          placeholder: 'Add Quantity',
          min: 0,
          value: element.qty_ordered,
        },
      ],
    });

    await alert.present();
  }

  async openItemModal(element) {
    const timeLineBody = {
      params: {
        item_id: element.item_id,
        site_id: this.userData.site_id,
      },
    };
    this.selectedItemOne = null;
    this.loading = true;
    const timeLineData: any = await this.poService.getTimeLineById(
      timeLineBody
    );
    const settings: any = await this.poService.getPoSettings();
    const itemSalesDays:any = await Promise.all(
      settings.map((x) =>
        this.poService.getItemSalesByDay({
          item_id: element.item_id,
          params: { site_id: this.userData.site_id, days: x.days },
        })
      )
    );

    this.selectedItemOne = {
      previous_sales: settings.reduce(
        (res, curr, index) => [
          ...res,
          { ...curr, count: itemSalesDays[index]?.length },
        ],
        []
      ),
      description: element.description,
      quantity: timeLineData[0]?.quantity,
      average_cost: timeLineData[0]?.average_cost,
      unit_price: timeLineData[0]?.unit_price,
      gross_margin: timeLineData[0]?.gross_margin,
    };
    this.loading = false;
  }
  async updateQty(element, value) {
    const data = _.cloneDeep(element);
    data.qty_ordered = value;
    data.qty_received = value;
    this.loading = true;
    const response = await this.poService.updatePurchaseOrderLinesById(data);
    const foundIndex = this.poLinesList.findIndex((x) => x.id == data.id);
    this.poLinesList[foundIndex] = response;
    this.initializeTable();
    this.loading = false;
    this.toastMsg('Purchase order quantity updated!!');
  }

  async selectItem(item) {
    const newLine = {
      ord:
        parseInt(
          this.poLinesList.reduce(
            (max, curr) =>
              parseInt(curr.ord) > parseInt(max) ? curr.ord : max,
            this.poLinesList[0]?.ord || 0
          )
        ) + 1,
      item_id: item.id,
      part_number: item.part_number || item.upc,
      upc: item.upc,
      plu: item.plu,
      description: item.description,
      unit_of_order: item.unit_of_order,
      case_size: item.case_size,
      unit_cost:
        item.unit_of_order !== 'EA'
          ? item.case_cost || item.unit_cost * item.case_size || item.unit_cost
          : item.last_cost,
      unit_discount: 0,
      discount_percentage: null,
      discount_amount: 0,
      tax: 0,
      qty_ordered: 0,
      qty_received: 0,
      old_average_cost: item.average_cost,
      new_average_cost: null,
      old_unit_price: item.unit_price,
      new_unit_price: item.unit_price,
      mrsp: null,
      status: 'open',
      vendor_id: item.vendor_id || null,
      id: this.id,
    };
    this.loading = true;
    const response = await this.poService.addPurchaseOrderLinesById(newLine);
    this.poLinesList.push(response);
    this.initializeTable();
    this.loading = false;
    this.editQty(response);
  }

  async removePurchaseOrderDetail(element) {
    try {
      this.loading = true;
      await this.poService.removePurchaseOrderDetail(element);
      this.poLinesList = this.poLinesList.filter((x) => x.id != element.id);
      this.initializeTable();
      this.toastMsg('Item removed');
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.toastMsg('Something went wrong!!!');
    }
  }

  async toastMsg(message) {
    const toast = await this.toastController.create({
      message,
      duration: 1500,
      position: 'bottom',
    });
    await toast.present();
  }

  sortCol(col) {
    this.columnSort[col] = this.columnSort[col]
      ? this.columnSort[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.columnSort).map((x) => {
      if (col != x) this.columnSort[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.columnSort[col] },
      { data: this.poLinesList },
      false
    );
    this.poLinesList = sorted.data;
  }
}
