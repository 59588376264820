import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private apiEndPoint: string = environment.apiEndPoint + 'api/1/';
  constructor(private http: HttpClient) {}

  postBatches(data) {
    return this.http.post(this.apiEndPoint + 'batches/post', data);
  }

  getCounts(data) {
    return this.http.post(this.apiEndPoint + 'counts', data);
  }

  addBatches(data) {
    return this.http.post(this.apiEndPoint + 'batches', data);
  }
  post_templines_id(obj) {
    return this.http.post(this.apiEndPoint + `purchase_orders_temp_lines`, obj);
  }
}
