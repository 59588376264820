import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PriceBookService {
  private apiEndPoint: string = environment.apiEndPoint + 'api/1/';
  constructor(private http: HttpClient) {}
  limitToFilter(input, limit) {
    if (!Array.isArray(input) && !(typeof input === 'string')) return input;

    if (Math.abs(Number(limit)) === Infinity) {
      limit = Number(limit);
    } else {
      limit = parseInt(limit);
    }

    if (typeof input === 'string') {
      //NaN check on limit
      if (limit) {
        return limit >= 0
          ? input.slice(0, limit)
          : input.slice(limit, input.length);
      } else {
        return '';
      }
    }

    var out = [],
      i,
      n;

    // if abs(limit) exceeds maximum length, trim it
    if (limit > input.length) limit = input.length;
    else if (limit < -input.length) limit = -input.length;

    if (limit > 0) {
      i = 0;
      n = limit;
    } else {
      i = input.length + limit;
      n = input.length;
    }

    for (; i < n; i++) {
      out.push(input[i]);
    }

    return out;
  }

  items_part(part, site_upc) {
    if (part.length === 12 && !isNaN(part)) {
      if (
        site_upc.upca_check_digit === true &&
        site_upc.upca_sys_number === true
      ) {
        return (part = part.substr(1, 10));
      } else if (site_upc.upca_check_digit === true) {
        return (part = part.substr(0, 11));
      } else if (site_upc.upca_sys_number === true) {
        return (part = part.substr(1, 11));
      } else {
        return part;
      }
    } else if (
      part.length === 8 &&
      !isNaN(part) &&
      site_upc.upce_check_digit === true &&
      site_upc.upce_sys_number === true
    ) {
      return (part = part.substr(1, 6));
    } else if (
      part.length === 8 &&
      !isNaN(part) &&
      site_upc.upce_check_digit === true
    ) {
      return (part = part.substr(0, 7));
    } else if (
      part.length === 8 &&
      !isNaN(part) &&
      site_upc.upce_sys_number === true
    ) {
      return (part = part.substr(1, 7));
    } else if (
      part.length === 13 &&
      !isNaN(part) &&
      site_upc.ean_check_digit === true
    ) {
      return (part = part.substr(0, 12));
    } else {
      return part;
    }
  }

  searchItem(part): Observable<any> {
    return this.http.get(
      this.apiEndPoint +
        `items/search/term/${part}?plu=true&upc=true&description=true`
    );
  }

  searchUPC(part): Observable<any> {
    return this.http.get(this.apiEndPoint + `items/search/upc/${part}`);
  }

  getItemById(id): Observable<any> {
    return this.http.get(this.apiEndPoint + `items/${id}`);
  }

  getDepartments(): Observable<any> {
    return this.http.get(this.apiEndPoint + `departments`);
  }
  getSubdepartments(): Observable<any> {
    return this.http.get(this.apiEndPoint + `subdepartments`);
  }

  postItemById(id, data): Observable<any> {
    return this.http.post(this.apiEndPoint + `items/${id}`, data);
  }
  getAllSites() {
    return this.http.get(this.apiEndPoint + 'sites');
  }

  getQohById(id) {
    return this.http.get(this.apiEndPoint + 'items/' + id + '/qoh');
  }
  getTaxLabels() {
    return this.http.get(this.apiEndPoint + 'clients/tax_labels');
  }
  getSalesRestrictionCodes() {
    return this.http.get(this.apiEndPoint + 'sales_restriction_codes');
  }
  getMarginHistory(id) {
    return this.http.get(this.apiEndPoint + 'items/' + id + '/margin_history');
  }

  getSummarizeReports(params) {
    return this.http.get(this.apiEndPoint + 'reports', { params }).toPromise();
  }

  postPricebookLabels(id, data) {
    return this.http.post(this.apiEndPoint + 'label/' + id, data);
  }
  deletePricebookLabels(id) {
    return this.http.delete(this.apiEndPoint + 'label/' + id);
  }

  getPricebookLabels(id) {
    return this.http.get(this.apiEndPoint + 'label/' + id);
  }

  getWhoami() {
    return this.http.get(this.apiEndPoint + 'whoami');
  }

  getPermissions() {
    return lastValueFrom(
      this.http.get(this.apiEndPoint + `user_roles_permissions`)
    );
  }

  getSiteUpcBySiteId(siteId) {
    return this.http.get(this.apiEndPoint + `site_upc/${siteId}`);
  }

  getSuppliers() {
    return this.http.get(this.apiEndPoint + 'suppliers');
  }
  getUserContent() {
    return this.http.get(this.apiEndPoint + 'ui/content');
  }
  getItemQoh(item_id, site_id) {
    return this.http.get(
      this.apiEndPoint + `items/${item_id}/${site_id}/item_qoh_only`
    );
  }
}
