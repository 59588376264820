import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DatePipe } from '@angular/common';
import * as commonFunc from '../../utilities/common-usage';
import * as _ from 'lodash';
import { SortPipe } from 'src/app/utilities/pipes/sort.pipe';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
})
export class AnalyticsComponent implements OnInit {
  @ViewChild('todaySalesCanvas') private todaySalesCanvas: ElementRef;
  @ViewChild('topItemsCanvas') private topItemsCanvas: ElementRef;
  @ViewChild('topDeptsCanvas') private topDeptsCanvas: ElementRef;
  analyticsForm: FormGroup;
  todaySalesChart: any;
  topDeptsChart: any;
  topItemsCharts: any;
  userData: any;
  realTimeFuelTankData: any;
  content: any;
  barHeight: any = '100vw';
  realTimeCount: any;
  commonFunc: any = commonFunc;
  datesList: any;
  topItemSales: any;
  topDepartmentSales: any;
  todaySalesLoader: boolean = false;
  topSalesLoader: boolean = false;
  isDarkTheme: boolean = false;
  maxDate: any;
  constructor(
    public sortPipe: SortPipe,
    public datepipe: DatePipe,
    public formBuilder: FormBuilder,
    private analyticsService: AnalyticsService,
    private toastController: ToastController
  ) {
    this.isDarkTheme = JSON.parse(
      localStorage.getItem('isDarkTheme') || 'false'
    );
    const thisMonthDates = this.commonFunc.this_month();
    const lastMonthDates = this.commonFunc.last_month();
    const lastSevenDayDates = this.commonFunc.Last_seven_days();
    const lastThirtyDayDates = this.commonFunc.last_thirty_days();
    const lastNinetyDayDates = this.commonFunc.Last_ninety_days();
    const lastSixMonthDates = this.commonFunc.Last_six_month();
    this.datesList = [
      {
        value: 'thisMonth',
        label: 'This month',
        starting: thisMonthDates.fromDate,
        ending: thisMonthDates.toDate,
      },
      {
        value: 'lastMonth',
        label: 'Last month',
        starting: lastMonthDates.fromDate,
        ending: lastMonthDates.toDate,
      },
      {
        value: 'lastSevenDays',
        label: 'Last seven days',
        starting: lastSevenDayDates.fromDate,
        ending: lastSevenDayDates.toDate,
      },
      {
        value: 'lastThirtyDays',
        label: 'Last thirty days',
        starting: lastThirtyDayDates.fromDate,
        ending: lastThirtyDayDates.toDate,
      },
      {
        value: 'lastNinetyDays',
        label: 'Last ninety days',
        starting: lastNinetyDayDates.fromDate,
        ending: lastNinetyDayDates.toDate,
      },
      {
        value: 'lastSixMonths',
        label: 'Last six months',
        starting: lastSixMonthDates.fromDate,
        ending: lastSixMonthDates.toDate,
      },
    ];
    Chart.register(...registerables);
    this.analyticsForm = this.formBuilder.group({
      dateRange: ['thisMonth'],
      business_date: [new Date().toJSON(), [Validators.required]],
      starting: [
        new Date(thisMonthDates.fromDate).toJSON(),
        [Validators.required],
      ],
      ending: [new Date(thisMonthDates.toDate).toJSON(), [Validators.required]],
    });
    const currentDate = new Date();
    this.maxDate = currentDate.toISOString().split('T')[0];
  }

  ngAfterViewInit() {
    this.getAllData();
    this.getTopSales();
  }
  ionViewWillEnter() {
    this.ngAfterViewInit();
  }
  handleRefresh(event) {
    this.getAllData(event);
  }
  async getAllData(event = null) {
    try {
      const { business_date } = this.analyticsForm.value;
      this.userData = await this.analyticsService.whoami();
      const body = {
        params: {
          business_date: this.datepipe.transform(business_date, 'yyyy-MM-dd'),
          site_id: this.userData.site_id,
        },
      };
      this.todaySalesLoader = true;
      const [realTimeFuelTankData, content, realTimeCount] = await Promise.all([
        this.analyticsService.getRealTimeFuelTank(body),
        this.analyticsService.getContent(),
        this.analyticsService.getRealTimeCount(body),
      ]);
      this.realTimeFuelTankData = realTimeFuelTankData;
      this.content = content;
      this.realTimeCount = realTimeCount;
      this.barHeight = this.realTimeCount.length
        ? `${this.realTimeCount.length * 3 * 3}vw`
        : '60vw';
      this.todaySalesChartMethod();
      if (event) event.target.complete();
      this.todaySalesLoader = false;
    } catch (err) {
      if (event) event.target.complete();
      this.todaySalesLoader = false;
      const toast = await this.toastController.create({
        message: 'Something went wrong!!!',
        duration: 1500,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  async getTopSales() {
    try {
      const { dateRange = 'thisMonth' } = this.analyticsForm.value;
      const dateRageFound = this.datesList.find(
        ({ value }) => value == dateRange
      );
      const starting = new Date(dateRageFound.starting).toJSON();
      const ending = new Date(dateRageFound.ending).toJSON();
      this.analyticsForm.patchValue({ starting, ending });
      this.userData = await this.analyticsService.whoami();
      const body = {
        params: {
          starting: this.datepipe.transform(starting, 'yyyy-MM-dd'),
          ending: this.datepipe.transform(ending, 'yyyy-MM-dd'),
          site_id: this.userData.site_id,
        },
      };
      this.topSalesLoader = true;
      const [topDepartmentSales, topItemSales] = await Promise.all([
        this.analyticsService.topDepartmentSales(body),
        this.analyticsService.topItemSales(body),
      ]);
      const groupedDept = _.groupBy(topDepartmentSales, 'description');
      const groupedItem = _.groupBy(topItemSales, 'description');
      this.topDepartmentSales = Object.keys(groupedDept).reduce(
        (res, key) => [
          ...res,
          {
            description: key,
            ...groupedDept[key].reduce(
              (res1, curr) => {
                res1.total_qty_sold = curr.qty_sold + res1.total_qty_sold;
                res1.total_amt_sold = curr.amount_sold + res1.total_amt_sold;
                return res1;
              },
              { total_qty_sold: 0, total_amt_sold: 0 }
            ),
          },
        ],
        []
      );
      this.topDepartmentSales = this.sortPipe
        .transform(this.topDepartmentSales, [
          '-total_qty_sold',
          '-total_amt_sold',
        ])
        .slice(0, 10);
      this.topItemSales = Object.keys(groupedItem).reduce(
        (res, key) => [
          ...res,
          {
            description: key,
            ...groupedItem[key].reduce(
              (res1, curr) => {
                res1.total_qty = curr.qty + res1.total_qty;
                return res1;
              },
              { total_qty: 0 }
            ),
          },
        ],
        []
      );
      this.topItemSales = this.sortPipe
        .transform(this.topItemSales, ['-total_qty'])
        .slice(0, 10);
      this.topDeptSalesChartMethod();
      this.topItemSalesChartMethod();
      this.topSalesLoader = false;
    } catch (err) {
      this.topSalesLoader = false;
      const toast = await this.toastController.create({
        message: 'Something went wrong!!!',
        duration: 1500,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  todaySalesChartMethod() {
    const labels = this.realTimeCount.map(({ ts }) => ts);
    const datasets = [
      {
        label: 'Items',
        borderColor: '#FFFFFF',
        backgroundColor: '#191919',
        data: this.realTimeCount.map(({ sales_amount }) => sales_amount),
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderWidth: 2,
      },
      {
        label: 'Fuel',
        borderColor: '#FFFFFF',
        backgroundColor: '#2CA02C',
        data: this.realTimeCount.map(({ fuel_amount }) => fuel_amount),
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderWidth: 2,
      },
      {
        label: 'Count',
        borderColor: '#FFFFFF',
        backgroundColor: '#FF7F0E',
        data: this.realTimeCount.map(({ trs_count }) => trs_count),
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderWidth: 2,
      },
    ];
    const config = this.getChartConfig(
      'Today Sales',
      'Sales ($)',
      'Time of Day',
      labels,
      datasets
    );
    if (this.todaySalesChart) this.todaySalesChart.destroy();
    this.todaySalesChart = new Chart(
      this.todaySalesCanvas.nativeElement,
      config
    );
    this.todaySalesChart.update();
  }

  topDeptSalesChartMethod() {
    const labels = this.topDepartmentSales.map(
      ({ description }) => description
    );
    const datasets = [
      {
        label: 'Quantity',
        borderColor: '#FFFFFF',
        backgroundColor: '#0000E1',
        data: this.topDepartmentSales.map(
          ({ total_qty_sold }) => total_qty_sold
        ),
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderWidth: 3,
      },
      {
        label: 'Sales ($)',
        borderColor: '#FFFFFF',
        backgroundColor: '#FF0000',
        data: this.topDepartmentSales.map(
          ({ total_amt_sold }) => total_amt_sold
        ),
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderWidth: 3,
      },
    ];
    const config = this.getChartConfig(
      'Sales Top 10 Departments',
      'Price or Quantity',
      'Description',
      labels,
      datasets
    );
    if (this.topDeptsChart) this.topDeptsChart.destroy();
    this.topDeptsChart = new Chart(this.topDeptsCanvas.nativeElement, config);
    this.topDeptsChart.update();
  }

  topItemSalesChartMethod() {
    const labels = this.topItemSales.map(({ description }) => description);
    const datasets = [
      {
        label: 'Quantity',
        borderColor: '#FFFFFF',
        backgroundColor: '#0000E1',
        data: this.topItemSales.map(({ total_qty }) => total_qty),
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        borderWidth: 3,
      },
    ];
    const config = this.getChartConfig(
      'Sales Top 10 Items',
      'Quantity',
      'Description',
      labels,
      datasets
    );
    if (this.topItemsCharts) this.topItemsCharts.destroy();
    this.topItemsCharts = new Chart(this.topItemsCanvas.nativeElement, config);
    this.topItemsCharts.update();
  }

  getChartConfig(
    chartTitle = '',
    xAxisTitle = '',
    yAxisTitle = '',
    labels = [],
    datasets = []
  ) {
    const config: ChartConfiguration = {
      type: 'bar',
      data: { labels, datasets },
      options: {
        indexAxis: 'y',
        elements: { bar: { borderWidth: 2 } },
        aspectRatio: 1,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { position: 'top' },
          title: { display: true, text: chartTitle },
        },
        scales: {
          y: { title: { display: true, text: yAxisTitle } },
          x: { title: { display: true, text: xAxisTitle } },
        },
      },
    };
    return config;
  }
  ngOnInit(): void {}
  addDate() {
    const { business_date } = this.analyticsForm.value;
    let date = new Date(business_date);
    date.setDate(date.getDate() + 1);
    this.analyticsForm.patchValue({ business_date: date.toJSON() });
    this.getAllData();
  }
  subtractDate() {
    const { business_date } = this.analyticsForm.value;
    let date = new Date(business_date);
    date.setDate(date.getDate() - 1);
    this.analyticsForm.patchValue({ business_date: date.toJSON() });
    this.getAllData();
  }
}
