import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { elementAt } from 'rxjs';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  lineChart: any;

  constructor() { }
  // http://198.55.49.57/api/1/reports?department_id=2481522&ending=2021-01-19&site_id=2481459&sort_by=date&starting=2020-04-06
  @Input() reports = [];
  @Input() labels = [];
  @Input() dataSet = [];
  @Input() timeSetup = {};
  @Input() chartChara = {};


  ngOnInit(): void {

  }

  ngOnChanges() {
    this.dataSet = this.dataSet.map((ele) => {
      return { ...ele, ...this.chartChara }
    })

    this.chartConfig()
  }

  chartConfig() {
    if (this.lineChart) {
      this.lineChart.destroy()
    }
    this.lineChart = new Chart('lineChart', {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.dataSet
      },
      options: {
        responsive: true, // Instruct chart js to respond nicely.
        maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              // maxTicksLimit: 3,
              // minRotation:0,
              // rotation:0,
              autoSkipPadding: 20,
              maxRotation: 0,
              // min:0,
              // max:12,
              // stepSize:5
            }
          },
        },
        plugins:{
          legend: {
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            backgroundColor: '#fff',
            bodyColor: '#000000',
            titleColor: '#000000',
            bodySpacing: 8,
            // titleMarginBottom:8
          },
        }
        ,
        
        hover: {
          mode: 'index',
          intersect: false
        }
      }
    })
  }

}
