import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, IonModal, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Observable } from 'rxjs';
import { InventoryService } from 'src/app/services/inventory.service';
import { LoginService } from 'src/app/services/login.service';
import { PriceBookService } from 'src/app/services/price-book.service';
import { date_and_time } from 'src/app/utilities/dates';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
})
export class InventoryComponent implements OnInit {
  @Input() events: Observable<void>;
  @Output() refreshCompletEvt = new EventEmitter<any>();
  items: any;
  search_value: any;
  selected_item: any;
  departments: any;
  subdepartments: any;
  sub_depts: any;
  user: any;
  site_upc: any;
  suppliers: any;
  user_info: any;
  qohs: any;
  loading: boolean;
  warning: boolean;
  item_qoh: any;
  selected_qoh: any;
  selected_site_id: any;
  // cdr: any;

  constructor(
    private pbService: PriceBookService,
    private invService: InventoryService,
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private toastController: ToastController,
    private alertController: AlertController
  ) {}

  ngOnInit(): void {
    this.getData();
    this.events?.subscribe((data: any) => {
      if (data == 'lookup') {
        this.getData();
      }
    });
  }

  handleRefresh(event) {
    this.getData(event);
  }

  getData(event = null) {
    this.pbService.getWhoami().subscribe((user: any) => {
      this.user = user;
      this.pbService.getSiteUpcBySiteId(user.site_id).subscribe((data) => {
        this.site_upc = data;
        this.pbService.getUserContent().subscribe((data1) => {
          this.user_info = data1[0];
          this.pbService.getDepartments().subscribe((data2) => {
            this.departments = data2;
            this.pbService.getSubdepartments().subscribe((data3) => {
              this.subdepartments = data3;
              this.pbService.getSuppliers().subscribe((data4) => {
                this.suppliers = data4;
                this.refreshCompletEvt.emit();
              });
            });
          });
        });
      });
    });
  }

  getItems(ev: any) {
    this.items = null;
    let item_part;
    let part = ev.target.value;
    if (part != null && part.length > 0) {
      if (this.site_upc)
        item_part = this.pbService.items_part(part, this.site_upc);
      this.pbService.searchUPC(part).subscribe((response) => {
        if (response.length === 1) {
          this.selectItem(response[0]);
          this.search_value = response[0].description;
          // return
          // console.log(here);
        }
        // console.log('here1111112');
        this.items = this.pbService.limitToFilter(response, 15);
        console.log(this.items);
      });
    }
  }

  selectItem(search_item) {
    this.search_value = search_item.description;
    this.pbService.getItemById(search_item.id).subscribe((item) => {
      this.selected_item = item;
      this.pbService
        .getItemQoh(item.id, this.user_info?.site_id)
        .subscribe((qohs: any) => {
          this.qohs = qohs;
          if (qohs) this.qoh_value = qohs.qoh;
        });

      this.sub_depts = this.subdepartments.filter((sub_dep) => {
        return sub_dep.department_id === search_item.department_id;
      });
    });
  }

  search_clear() {
    this.selected_item = null;
    this.search_value = null;
    this.items = null;
  }

  select_sub_dep(selected_dept) {
    this.selected_item.subdepartment_id = null;
    return (this.sub_depts = this.subdepartments.filter(function (sub_dep) {
      return sub_dep.department_id === selected_dept;
    }));
  }

  @ViewChild('qohModal') modal: IonModal;
  name: string;
  message = '';
  qoh_value;
  cancelQoh() {
    this.modal.dismiss(null, 'cancel');
    this.qoh_value = this.qohs.qoh;
  }

  confirmQoh() {
    this.modal.dismiss(this.name, 'confirm');
    // this.qohs.qoh = this.qoh_value;
    this.post_item_qoh();
  }

  // onWillDismiss(event: Event) {

  //   const ev = event as CustomEvent<OverlayEventDetail<string>>;
  //   if (ev.detail.role === 'confirm') {
  //     this.message = `Hello, ${ev.detail.data}!`;
  //   }
  // }

  async post_item_qoh() {
    let line = { ...this.qohs };
    line.qoh = this.qoh_value;
    // await this.spinnerService.showSpinner()
    var add_item_to_count, batch, post_batch, save_error;
    this.loading = true;
    this.warning = false;
    // this.itemQohForm.get('qoh').disable();
    save_error = async (err) => {
      // await this.spinnerService.hideSpinner()
      this.qoh_value = this.qohs.qoh;
      const toast = await this.toastController.create({
        message: 'Error !!',
        duration: 2000,
        cssClass: 'custom-toast',
        position: 'bottom',
      });
      await toast.present();
    };
    // posting the batch
    post_batch = (batch) => {
      var b, post_date_time, r;
      b = [];
      b.push(batch.id);
      post_date_time = new Date(new Date());

      this.invService
        .postBatches({
          comments: 'posted from mobile to correct the qoh',
          batch_ids: b,
          ts: date_and_time(post_date_time),
        })
        .subscribe(
          async (results) => {
            // this.itemQohForm.get('qoh').patchValue(this.selected_qoh)
            this.item_qoh = line.qoh;
            this.loading = false;
            this.selected_qoh = null;
            this.selected_site_id = null;
            // await this.spinnerService.hideSpinner()
            this.qohs.qoh = this.qoh_value;
            const toast = await this.toastController.create({
              message: 'Adjustment posted!!',
              duration: 2000,
              cssClass: 'custom-toast',
              position: 'bottom',
            });
            await toast.present();
          },
          async (err) => {
            this.loading = false;
            this.selected_qoh = null;
            this.selected_site_id = null;
            // await this.spinnerService.hideSpinner()

            const toast = await this.toastController.create({
              message: 'Adjustment posting Error!!',
              duration: 2000,
              cssClass: 'custom-toast',
              position: 'bottom',
            });
            await toast.present();
          }
        );
    };

    const date_and_time = (d) => {
      var date, dt, month;
      if (d != null && d !== '') {
        date = new Date(d);
        month = date.getMonth() + 1;
        dt =
          date.getFullYear() +
          '-' +
          month +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds();
        return dt;
      } else {
        return null;
      }
    };

    // adding item to the batch
    add_item_to_count = async (batch) => {
      this.invService
        .getCounts({
          batch_id: batch.id,
          location_code: 'other',
          selected_item_id: null,
          item_id: line.item_id,
          count: line.qoh,
        })
        .subscribe(
          (r) => {
            post_batch(batch);
          },
          (err) => {
            save_error(err);
          }
        );
    };

    // creating a new batch

    this.invService
      .addBatches({
        site_id: line.site_id,
        name: 'Mobile Adjustment',
        source_code: 'manual',
      })
      .subscribe(
        (batch) => {
          add_item_to_count(batch);
        },
        (err) => {
          save_error(err);
        }
      );
    // this.cdr.detectChanges()
  }

  @ViewChild('POModal') modal2: IonModal;
  // name: string;
  // message = '';
  // qoh_value
  selected_item_qty = 0;

  cancelPO() {
    this.modal2.dismiss(null, 'cancel');
    this.selected_item_qty = 0;
  }

  confirmPO() {
    this.modal2.dismiss(this.name, 'confirm');
    // this.qohs.qoh = this.qoh_value;
    this.add_po_temp_lines();
  }

  add_po_temp_lines() {
    let lines = { ...this.selected_item };
    lines.qty_ordered = this.selected_item_qty;

    let new_line = {
      item_id: lines.id,
      site_id: this.user_info.site_id,
      supplier_id: lines.supplier_id,
      part_number: lines.part_number || null,
      upc: lines.upc || null,
      plu: lines.plu || null,
      description: lines.description || null,
      unit_of_order: lines.unit_of_order || 'EA',
      case_size: lines.case_size || 1,
      unit_cost:
        lines.case_cost > 0
          ? lines.case_cost
          : lines.average_cost * lines.case_size,
      tax: 0,
      qty_ordered: lines.qty_ordered,
      average_cost: lines.average_cost || lines.last_cost,
      new_unit_price: lines.unit_price,
      msrp: lines.unit_price,
    };

    this.invService.post_templines_id(new_line).subscribe(
      async (data) => {
        const toast = await this.toastController.create({
          message: 'Updated!!',
          duration: 2000,
          cssClass: 'custom-toast',
          position: 'bottom',
        });
        await toast.present();
        this.selected_item_qty = 0;
      },
      async (err) => {
        const toast = await this.toastController.create({
          message: 'Error !!',
          duration: 2000,
          cssClass: 'custom-toast',
          position: 'bottom',
        });
        await toast.present();
        this.selected_item_qty = 0;
      }
    );
  }

  post_item() {
    this.pbService
      .postItemById(this.selected_item.id, this.selected_item)
      .subscribe(
        async (status) => {
          const toast = await this.toastController.create({
            message: 'Success !!  Item Saved!',
            duration: 2000,
            cssClass: 'custom-toast',
            position: 'bottom',
          });

          await toast.present();
        },
        async (err) => {
          const toast = await this.toastController.create({
            message: 'Error !! Item can not be Saved',
            duration: 2000,
            cssClass: 'custom-toast',
            position: 'bottom',
          });

          await toast.present();
        }
      );
  }

  async openQohModal() {
    let val = this.qoh_value;
    const alert = await this.alertController.create({
      header: 'QOH Will Be Updated Permanently',
      cssClass: 'count-alert',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
          cssClass: 'count-button-cancel',
        },
        {
          text: 'Save',
          role: 'confirm',
          handler: async (data) => {
            if (data['0'] == null || data['0'] == '') {
              const toast = await this.toastController.create({
                message: 'Please enter valid Quantity !!',
                duration: 2000,
                cssClass: 'custom-warn-toast',
                position: 'bottom',
              });
              await toast.present();
              return false;
            }

            this.qoh_value = parseFloat(data[0]);
            this.post_item_qoh();
          },
          cssClass: 'count-button-confirm',
        },
      ],
      inputs: [
        {
          type: 'number',
          placeholder: 'Update QOH',
          min: 0,
          value: val,
        },
      ],
    });

    await alert.present();
  }

  async openPOModal() {
    let val = this.selected_item_qty;
    const alert = await this.alertController.create({
      header: 'Enter the PO Quantity',
      cssClass: 'count-alert',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.selected_item_qty = 0;
          },
          cssClass: 'count-button-cancel',
        },
        {
          text: 'Save',
          role: 'confirm',
          handler: async (data) => {
            if (data['0'] == null || data['0'] == '') {
              const toast = await this.toastController.create({
                message: 'Please enter valid Quantity !!',
                duration: 2000,
                cssClass: 'custom-warn-toast',
                position: 'bottom',
              });
              await toast.present();
              return false;
            }

            this.selected_item_qty = parseFloat(data[0]);

            this.add_po_temp_lines();
          },
          cssClass: 'count-button-confirm',
        },
      ],
      inputs: [
        {
          type: 'number',
          placeholder: 'Quantity',
          min: 0,
          value: val,
        },
      ],
    });

    await alert.present();
  }
}
