import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private apiEndPoint: string = environment.apiEndPoint;
  public homePage: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(private http: HttpClient) {}

  loginFunction(data: any): Observable<any> {
    return this.http.post(this.apiEndPoint + 'login_ng', data);
  }
  logoutFunction(): Observable<any> {
    return this.http.get(this.apiEndPoint + 'logout_ng');
  }
}
