import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics.service';
import * as commonFunc from '../../utilities/common-usage';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-live-sales',
  templateUrl: './live-sales.component.html',
  styleUrls: ['./live-sales.component.css'],
})
export class LiveSalesComponent implements OnInit {
  @Input() events: Observable<void>;
  @Output() refreshCompletEvt = new EventEmitter<any>();
  liveSalesForm: FormGroup;
  liveSalesList: any;
  liveStoreSalesList: any;
  liveSalesSource: any;
  departmentsSource: any;
  supplierSource: any;
  itemSource: any;
  liveSalesTotAmt: any = 0;
  liveSalesTotQty: any = 0;
  liveStoreSalesTotAmt: any = 0;
  liveStoreSalesTotQty: any = 0;
  commonFunc: any = commonFunc;
  liveSalesColumns: any = [
    'description',
    'avg_price',
    'amount_sold',
    'qty_sold',
  ];
  departmentsColumns: any = ['description', 'amount_sold', 'qty_sold'];
  supplierColumns: any = ['supplier_name', 'amount_sold', 'qty_sold'];
  itemColumns: any = ['plu', 'description', 'amount_sold', 'qty_sold'];
  total_amt: any = 0;
  total_qty: any = 0;
  userData: any;
  segmentValue: any = 'department';
  loading: boolean = false;
  isDarkTheme: boolean;
  liveSalesColumnSort: any = {
    description: null,
    avg_price: null,
    amount_sold: null,
    qty_sold: null,
  };
  deptColumnSort: any = {
    description: null,
    amount_sold: null,
    qty_sold: null,
  };
  suppColumnSort: any = {
    supplier_name: null,
    amount_sold: null,
    qty_sold: null,
  };
  itemColumnSort: any = {
    plu: null,
    description: null,
    amount_sold: null,
    qty_sold: null,
  };
  maxDate: any;
  constructor(
    public formBuilder: FormBuilder,
    private analyticsService: AnalyticsService,
    public datepipe: DatePipe,
    private toastController: ToastController
  ) {
    this.liveSalesForm = this.formBuilder.group({
      business_date: [new Date().toJSON(), [Validators.required]],
      liveSalesFilter: [''],
      departmentFilter: [''],
      supplierFilter: [''],
      itemFilter: [''],
    });
    const currentDate = new Date();
    this.maxDate = currentDate.toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.isDarkTheme = JSON.parse(
      localStorage.getItem('isDarkTheme') || 'false'
    );
    this.getAllData();
    this.events?.subscribe(async (data: any) => {
      if (data == 'liveSales') {
        await this.getAllData();
        this.refreshCompletEvt.emit();
      }
    });
  }

  async getAllData() {
    try {
      const { business_date } = this.liveSalesForm.value;
      this.userData = await this.analyticsService.whoami();
      const body = {
        params: {
          business_date: this.datepipe.transform(business_date, 'yyyy-MM-dd'),
          site_id: this.userData.site_id,
        },
      };
      this.loading = true;
      const [liveSalesList, liveStoreSalesList] = await Promise.all([
        this.analyticsService.getRealTimeFuel(body),
        this.analyticsService.getRealTimeItems(body),
      ]);
      // if (event) event.target.complete();
      this.liveSalesList = liveSalesList;
      this.liveStoreSalesList = liveStoreSalesList;
      const { liveSalesTotAmt, liveSalesTotQty } = this.liveSalesList.reduce(
        (res, curr) => {
          res.liveSalesTotAmt = curr.amount_sold + res.liveSalesTotAmt;
          res.liveSalesTotQty = curr.qty_sold + res.liveSalesTotQty;
          return res;
        },
        { liveSalesTotAmt: 0, liveSalesTotQty: 0 }
      );
      const { liveStoreSalesTotAmt, liveStoreSalesTotQty } =
        this.liveStoreSalesList.reduce(
          (res, curr) => {
            res.liveStoreSalesTotAmt =
              curr.amount_sold + res.liveStoreSalesTotAmt;
            res.liveStoreSalesTotQty = curr.qty_sold + res.liveStoreSalesTotQty;
            return res;
          },
          { liveStoreSalesTotAmt: 0, liveStoreSalesTotQty: 0 }
        );
      this.liveSalesTotAmt = liveSalesTotAmt;
      this.liveSalesTotQty = liveSalesTotQty;
      this.liveStoreSalesTotAmt = liveStoreSalesTotAmt;
      this.liveStoreSalesTotQty = liveStoreSalesTotQty;
      this.liveSalesList = _.groupBy(this.liveSalesList, 'grade_id');
      this.liveSalesList = Object.keys(this.liveSalesList).reduce(
        (res, key) => {
          const amount_sold = this.liveSalesList[key].reduce(
            (res1, curr) => res1 + curr.amount_sold,
            0
          );
          const qty_sold = this.liveSalesList[key].reduce(
            (res1, curr) => res1 + curr.qty_sold,
            0
          );
          const obj = {
            grade_id: key,
            description: this.liveSalesList[key][0].description,
            amount_sold,
            qty_sold,
            avg_price: (amount_sold / qty_sold).toFixed(3),
          };
          return [...res, obj];
        },
        []
      );
      const deptGroup = _.groupBy(this.liveStoreSalesList, 'department_id');
      const deptTableSource = Object.keys(deptGroup).reduce((res, key) => {
        const amount_sold = deptGroup[key].reduce(
          (res1, curr) => res1 + curr.amount_sold,
          0
        );
        const qty_sold = deptGroup[key].reduce(
          (res1, curr) => res1 + curr.qty_sold,
          0
        );
        const obj = {
          department_id: key,
          description: deptGroup[key][0].description,
          department_name: deptGroup[key][0]?.department_name || '-',
          amount_sold,
          qty_sold,
        };
        return [...res, obj];
      }, []);
      const suppGroup = _.groupBy(this.liveStoreSalesList, 'supplier_id');
      const suppTableSource = Object.keys(suppGroup).reduce((res, key) => {
        const amount_sold = suppGroup[key].reduce(
          (res1, curr) => res1 + curr.amount_sold,
          0
        );
        const qty_sold = suppGroup[key].reduce(
          (res1, curr) => res1 + curr.qty_sold,
          0
        );
        const obj = {
          supplier_id: key,
          supplier_name: suppGroup[key][0].supplier_name,
          amount_sold,
          qty_sold,
        };
        return [...res, obj];
      }, []);
      const itemGroup = _.groupBy(this.liveStoreSalesList, 'item_id');
      const itemTableSource = Object.keys(itemGroup).reduce((res, key) => {
        const amount_sold = itemGroup[key].reduce(
          (res1, curr) => res1 + curr.amount_sold,
          0
        );
        const qty_sold = itemGroup[key].reduce(
          (res1, curr) => res1 + curr.qty_sold,
          0
        );
        const obj = {
          item_id: key,
          plu: itemGroup[key][0].plu,
          description: itemGroup[key][0].description,
          amount_sold,
          qty_sold,
        };
        return [...res, obj];
      }, []);
      this.liveSalesSource = new MatTableDataSource(this.liveSalesList);
      this.departmentsSource = new MatTableDataSource(deptTableSource);
      this.supplierSource = new MatTableDataSource(suppTableSource);
      this.itemSource = new MatTableDataSource(itemTableSource);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      const toast = await this.toastController.create({
        message: 'Something went wrong!!!',
        duration: 1500,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  filter() {
    const { liveSalesFilter, departmentFilter, supplierFilter, itemFilter } =
      this.liveSalesForm.value;
    this.liveSalesSource.filter = liveSalesFilter.trim().toLowerCase();
    this.departmentsSource.filter = departmentFilter.trim().toLowerCase();
    this.supplierSource.filter = supplierFilter.trim().toLowerCase();
    this.itemSource.filter = itemFilter.trim().toLowerCase();
  }

  async goToItemSegment(element, key) {
    const itemGroup = _.groupBy(
      this.liveStoreSalesList.filter((x) => x[key] == element[key]),
      'item_id'
    );
    const itemTableSource = Object.keys(itemGroup).reduce((res, key) => {
      const amount_sold = itemGroup[key].reduce(
        (res1, curr) => res1 + curr.amount_sold,
        0
      );
      const qty_sold = itemGroup[key].reduce(
        (res1, curr) => res1 + curr.qty_sold,
        0
      );
      const obj = {
        item_id: key,
        plu: itemGroup[key][0].plu,
        description: itemGroup[key][0].description,
        amount_sold,
        qty_sold,
      };
      return [...res, obj];
    }, []);
    this.itemSource = new MatTableDataSource(itemTableSource);
    document.getElementById('itemEle').click();
  }

  segmentChanged(e) {
    this.segmentValue = e.detail.value;
  }

  addDate() {
    const { business_date } = this.liveSalesForm.value;
    let date = new Date(business_date);
    date.setDate(date.getDate() + 1);
    this.liveSalesForm.patchValue({ business_date: date.toJSON() });
    this.getAllData();
  }
  subtractDate() {
    const { business_date } = this.liveSalesForm.value;
    let date = new Date(business_date);
    date.setDate(date.getDate() - 1);
    this.liveSalesForm.patchValue({ business_date: date.toJSON() });
    this.getAllData();
  }
  liveSalesSortCol(col) {
    this.liveSalesColumnSort[col] = this.liveSalesColumnSort[col]
      ? this.liveSalesColumnSort[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.liveSalesColumnSort).map((x) => {
      if (col != x) this.liveSalesColumnSort[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.liveSalesColumnSort[col] },
      { data: this.liveSalesSource.filteredData },
      false
    );
    this.liveSalesSource.filteredData = sorted.data;
  }
  deptSortCol(col) {
    this.deptColumnSort[col] = this.deptColumnSort[col]
      ? this.deptColumnSort[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.deptColumnSort).map((x) => {
      if (col != x) this.deptColumnSort[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.deptColumnSort[col] },
      { data: this.departmentsSource.filteredData },
      false
    );
    this.departmentsSource.filteredData = sorted.data;
  }
  suppSortCol(col) {
    this.suppColumnSort[col] = this.suppColumnSort[col]
      ? this.suppColumnSort[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.suppColumnSort).map((x) => {
      if (col != x) this.suppColumnSort[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.suppColumnSort[col] },
      { data: this.supplierSource.filteredData },
      false
    );
    this.supplierSource.filteredData = sorted.data;
  }
  itemSortCol(col) {
    this.itemColumnSort[col] = this.itemColumnSort[col]
      ? this.itemColumnSort[col] == 'asc'
        ? 'desc'
        : 'asc'
      : 'asc';
    Object.keys(this.itemColumnSort).map((x) => {
      if (col != x) this.itemColumnSort[x] = null;
    });
    const sorted = this.commonFunc.sortData(
      { active: col, direction: this.itemColumnSort[col] },
      { data: this.itemSource.filteredData },
      false
    );
    this.itemSource.filteredData = sorted.data;
  }
}
