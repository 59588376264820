import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountsService {

  private apiEndPoint: string = environment.apiEndPoint + 'api/1/';
  constructor(private http: HttpClient) { }
  
  getBatches(params ) {
    return (this.http.get(this.apiEndPoint + `batches`, { params }));
  }
  postBatches(data ) {
    return (this.http.post(this.apiEndPoint + `batches`, data));
  }
  getCounts(params ) {
    return (this.http.get(this.apiEndPoint + `counts`, { params }));
  }
  getCountLocations( ) {
    return (this.http.get(this.apiEndPoint + `count_locations`));
  }
  
  getBatchesById(id ) {
    return (this.http.get(this.apiEndPoint + `batches/${id}`));
  }

  addCounts(data ) {
    return (this.http.post(this.apiEndPoint + `counts`, data));
  }
  addCountsById(data ) {
    return (this.http.post(this.apiEndPoint + `counts/${data.id}`, data));
  }
  removeCountsById(id ) {
    return (this.http.delete(this.apiEndPoint + `counts/${id}`));
  }
}
